<template>
    <div>
        <ReviewAppDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <ReviewDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <StatusDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <RepeatDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <PopupBanner
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <Onboarding
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="false"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import ReviewDialog from '@/components/dialogs/ReviewDialog';
    import ReviewAppDialog from '@/components/dialogs/ReviewAppDialog';
    import StatusDialog from '@/components/dialogs/StatusDialog';
    import RepeatDialog from '@/components/dialogs/RepeatDialog';
    import PopupBanner from '@/components/dialogs/PopupBanner';
    import Onboarding from '@/components/dialogs/Onboarding';
    import indexedDB from '@/plugins/indexedDB';

    export default {
        name: 'StatusObserver',

        components: {
            ReviewDialog,
            StatusDialog,
            RepeatDialog,
            PopupBanner,
            Onboarding,
            ReviewAppDialog
        },

        props: {
            routerName: {
                type: String,
                default: ''
            }
        },
        
        data: () => ({
            canOpen: false,
            inProcess: false,
            routeName: null
        }),

        computed: {
            ...mapState('orders', ['ordersStatuses']),
        },

        watch: {
            async inProcess() {
                await this.checkOrdersInWork();
            },
        },

        async mounted() {
            await this.checkRouteName();
            await this.clearOldStorage();
            
            this.$root.$on('newOrderInWork', this.checkOrdersInWork);
            await this.checkOrdersInWork();
        },

        methods: {
            async checkRouteName() {
                await this.timeout(200);
                const routeName = this?.$f7?.views?.main?.router?.url;
                if(this.routeName && this.routeName !== '/' && this.routeName !== routeName) {
                    this.$root.$emit('onboardingClose');
                }
                if(this.routeName !== routeName) {
                    if(routeName !== '/' && routeName !== '/home') {
                        this.$root.$emit('onboardingShow', { routeName });
                        this.routeName = routeName;
                    } else {
                        if (this.ordersStatuses?.length === 0) {
                            this.$root.$emit('onboardingShow', {});
                            this.routeName = routeName;
                        }
                    }
                }
                
                await this.checkRouteName();
                return;
            },

            async checkIfSheetOpen() {
                await this.timeout(2000);
                const sheet = this?.$f7?.sheet?.get();
                if (sheet?.opened) {
                    this.canOpen = false;
                    this.checkIfSheetOpen();
                    return;
                }

                this.canOpen = true;
                return;
            },

            async checkOrdersInWork() {
                if (this.inProcess) {
                    return;
                }
                await this.timeout(10000);

                let ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                try {
                    const ordersFromServer = await store.dispatch('orders/getByIds', { orderIds: ordersInWork.map(order => order?.id) });
                    
                    if(ordersFromServer && ordersFromServer.length > 0) {
                        ordersInWork = ordersInWork.filter(order => {
                            for(const item of ordersFromServer) {
                                if(item.id === order.id) {
                                    return true;
                                }
                            }
                            return false;
                        });
                        await indexedDB.set('mircity-ordersInWork', ordersInWork);
                    } else {
                        await indexedDB.remove('mircity-ordersInWork');
                    }
                } catch (e) {}
                
                await this.checkOrdersInWork();
            },

            waitProcessing(val = false) {
                this.inProcess = !!val;
            },

            // ! Should be deleted after some weeks...
            async clearOldStorage() {
                const ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                const clearedOrders = ordersInWork?.filter(order => order != null && order.constructor === Object);
                await indexedDB.set('mircity-ordersInWork', clearedOrders);
            },

            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
        }
    };
</script>
