<template>
    <div class="status-dialogs-content">
        <div class="status-dialogs-content__inline" @click="openOrder(order)">
            <img :src="`static/statuses/${getImgStatus(order)}.svg`" />
            <div class="status-dialog-wrapper-content">
                <div class="status-dialogs-additional-text">№ {{ order.id }}</div>
                <div class="status-dialogs-main-text">{{ getStatus(order) }}</div>
            </div>
            <button
                v-if="order.status === ORDER_STATUS.CANCELED && checkEnabledOrder(order)"
                class="status-dialogs-button"
                @click="repeatOrder(order)">
                <span>Повторить</span>
            </button>
            <button
                v-if="!processingPayment(order) && checkPostpaid(order) && (order.status === ORDER_STATUS.SUBMITTED || order.status === ORDER_STATUS.PREPARED)"
                class="status-dialogs-button"
                @click="payOrder(order)">
                <span>Оплатить</span>
            </button>
        </div>
        <div v-if="order.status === ORDER_STATUS.MEETING_POINT">
            <div class="status-dialogs-content__separate"></div>
            <div class="status-dialogs-content__inline">
                <img :src="paidWaitingFree ? `static/paid-waiting-blue.svg` : `static/paid-waiting-red.svg`" />
                <div class="status-dialogs-content__paid-waiting">
                    <div class="status-dialogs-content__paid-waiting__timer">{{ paidWaitingTimer }}</div>
                    {{ paidWaitingFree ? 'До конца бесплатного ожидания' : 'Время платного ожидания' }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import moment from '../../plugins/moment'
    import { STATUS_DIALOGS_TYPES, DELIVERY_TYPE, ORDER_STATUS, ORDER_PAYMENT_STATUS } from '@/js/utils/vars'
    import { repeatOrder } from '@/js/utils/orders';

    import { sendAppMetricaEvent, checkEnabledService } from '@/js/utils/service-helpers';

    export default {
        name: 'StatusDialogOrderCard',

        props: {
            order: {
                type: Object,
                default: {},
            },
        },
        
        data: () => ({
            ORDER_STATUS,
            shown: false,
            intervalId: null,
            freeSeconds: 195 * 60,
            paidWaitingFree: true,
            paidWaitingTimer: ''
        }),

        created() {
            this.startTimer();
        },
        destroyed() {
            clearInterval(this.intervalId);
        },

        methods: {
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
                this.paidWaitingFetch();
                this.intervalId = setInterval(() => {
                    this.paidWaitingFetch();
                }, 1000);
            },
            paidWaitingFetch() {
                if (this.order.status === ORDER_STATUS.MEETING_POINT) {
                    if (this.order.status === ORDER_STATUS.MEETING_POINT) {
                        const currentTime = moment().unix();
                        const updatedTime = moment(this.order.meeting_point_at).unix();
                        this.paidWaitingFree = currentTime - updatedTime < this.order.waiting_free_seconds;
                        let ms = (currentTime - updatedTime) - this.order.waiting_free_seconds;
                        if (this.paidWaitingFree) {
                            ms = this.order.waiting_free_seconds - (currentTime - updatedTime);
                        }
                        if(Math.floor(ms / 60) > 10) {
                            this.paidWaitingTimer = `${Math.floor(ms / 60)}:${('0' + ms % 60).slice(-2)}`;
                        } else {
                            this.paidWaitingTimer = `${('0' + Math.floor(ms / 60)).slice(-2)}:${('0' + ms % 60).slice(-2)}`;
                        }
                    }
                }
            },
            getStatus(order) {
                if ((order.status === ORDER_STATUS.SUBMITTED || order.status === ORDER_STATUS.PREPARED) && this.processingPayment(order)) {
                    return 'Платеж обрабатывается';
                }
                if (!order.statusDialogs.length) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // постоплата до двери
                                return this.labelStatusType(statusDialog.postpaid_door, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // постоплата в точку встречи
                                return this.labelStatusType(statusDialog.postpaid_meeting_point, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // постоплата без доставки
                                return this.labelStatusType(statusDialog.postpaid_without, order.code);
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // до двери
                                return this.labelStatusType(statusDialog.prepayment_door, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // в точку встречи
                                return this.labelStatusType(statusDialog.prepayment_meeting_point, order.code);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // без доставки
                                return this.labelStatusType(statusDialog.prepayment_without, order.code);
                            }
                        }
                    
                    }
                }
                return;
            },
            processingPayment(order) {
                return order && order.payments ? order.payments.find(item => item.status === ORDER_PAYMENT_STATUS.PROCESSING) : false
            },
            payOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_PAY_ORDER', {
                    Action: 'pay',
                });
                this.$f7router.navigate({
                    name: 'payment',
                    params: {
                        orderType: order.type,
                        orderId: order.id
                    },
                });
            },
            getImgStatus(order) {
                if (!order.statusDialogs.length) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // постоплата до двери
                                return this.imgStatusType(statusDialog.postpaid_door);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // постоплата в точку встречи
                                return this.imgStatusType(statusDialog.postpaid_meeting_point);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // постоплата без доставки
                                return this.imgStatusType(statusDialog.postpaid_without);
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) { // до двери
                                return this.imgStatusType(statusDialog.prepayment_door);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) { // в точку встречи
                                return this.imgStatusType(statusDialog.prepayment_meeting_point);
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) { // без доставки
                                return this.imgStatusType(statusDialog.prepayment_without);
                            }
                        }
                    }
                }
                return
            },
            imgStatusType(statusType) {
                switch (statusType) {
                case STATUS_DIALOGS_TYPES.NOT_PAID: // не оплачен
                    return 'NotPay';
                case STATUS_DIALOGS_TYPES.AWAITING: // собираем
                    return 'Assembly';
                case STATUS_DIALOGS_TYPES.COOKING: // готовим
                    return 'Cooking';
                case STATUS_DIALOGS_TYPES.DELIVERY: // консьерж в пути
                    return 'DeliveryLeg';
                case STATUS_DIALOGS_TYPES.DONE: // доставлен
                    return 'Checkround';
                case STATUS_DIALOGS_TYPES.CANCELED: // отменен 
                    return 'Cancel2';
                case STATUS_DIALOGS_TYPES.AWAITING_READY: // готов к выдаче (в рест , в маг..)
                    return 'Shop';
                case STATUS_DIALOGS_TYPES.MEETING_POINT: // в точке встречи
                    return 'MeetingPoint';
                case STATUS_DIALOGS_TYPES.NOT_PAID_POST: // заказ принят (постоплата)
                    return 'OrderAccepted';
                case STATUS_DIALOGS_TYPES.IN_WORK: // в работе
                    return 'Assembly';
                case STATUS_DIALOGS_TYPES.COMPLETED: // заказ выполнен (постоплата)
                    return 'Checkround';
                case STATUS_DIALOGS_TYPES.DELIVERED_DOOR: // доставлен до двери
                    return 'Checkround';
                default:
                    return;
                }
            },
            labelStatusType(statusType, serviceName) {
                const types = [];
                if (serviceName === 'restaurant') {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COOKING, label: 'Готовим' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче в ресторане' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'Готов к выдаче в точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                } else if (serviceName === 'grocery') {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID_POST, label: 'Заказ принят' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING, label: 'Собираем' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче в магазине' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'Готов к выдаче в точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                } else {
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID_POST, label: 'Заказ принят' });
                    types.push({ value: STATUS_DIALOGS_TYPES.NOT_PAID, label: 'Не оплачен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING, label: 'Собираем' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COOKING, label: 'Готовим' });
                    types.push({ value: STATUS_DIALOGS_TYPES.IN_WORK, label: 'В работе' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERY, label: 'Консьерж в пути' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DONE, label: 'Доставлен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.CANCELED, label: 'Отменен' });
                    types.push({ value: STATUS_DIALOGS_TYPES.AWAITING_READY, label: 'Готов к выдаче' });
                    types.push({ value: STATUS_DIALOGS_TYPES.MEETING_POINT, label: 'В точке встречи' });
                    types.push({ value: STATUS_DIALOGS_TYPES.COMPLETED, label: 'Уже у вас' });
                    types.push({ value: STATUS_DIALOGS_TYPES.DELIVERED_DOOR, label: 'Доставлен до двери' });
                }
                for (const type of types) {
                    if (type.value === statusType) {
                        return type.label;
                    }
                }
                return '';
            },
            async repeatOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_REPEAT_ORDER', {
                    Action: 'repeat',
                });
                await repeatOrder(order);
            },
            checkStatus(order) {
                if (!order) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if ((order?.gd?.items[0]?.grocer?.status_postpay && statusDialog.postpaid) || (!order.gd && statusDialog.postpaid)) {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) {
                                return statusDialog.postpaid_door && statusDialog.postpaid_door !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) {
                                return statusDialog.postpaid_meeting_point && statusDialog.postpaid_meeting_point !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) {
                                return statusDialog.postpaid_without && statusDialog.postpaid_without !== 0;
                            }
                        } else {
                            if (order.delivery_type && (order.delivery_type === DELIVERY_TYPE.DOOR || order.delivery_type === DELIVERY_TYPE.COLLECT)) {
                                return statusDialog.prepayment_door && statusDialog.prepayment_door !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.MEETING_POINT) {
                                return statusDialog.prepayment_meeting_point && statusDialog.prepayment_meeting_point !== 0;
                            }
                            if (order.delivery_type && order.delivery_type === DELIVERY_TYPE.WITHOUT) {
                                return statusDialog.prepayment_without && statusDialog.prepayment_without !== 0;
                            }
                        }
                    }
                }
                return false;
            },
            checkPostpaid(order) {
                if (!order) {
                    return;
                }
                for (const statusDialog of order.statusDialogs) {
                    if (order.status === statusDialog.status) {
                        if (order.gd) {
                            return !order?.gd?.items[0]?.grocer?.status_postpay;
                        } else {
                            return !statusDialog.postpaid;
                        }
                    }
                }
            },
            checkEnabledOrder(order) {
                return checkEnabledService(order);
            },
            openOrder(order) {
                sendAppMetricaEvent('STATUS_DIALOGS_OPEN_ORDER', {
                    Action: 'open',
                });
                const orderId = order.id;
                if (!order?.gd?.items[0]?.grocer?.status_postpay && order.status === ORDER_STATUS.SUBMITTED || order.status === ORDER_STATUS.CANCELED) {
                    return;
                }
                this.$f7router.navigate({
                    name: 'orders-detail',
                    params: {
                        orderId
                    }
                });
            },
        }
    };
</script>

<style lang="scss">
.status-dialogs-toolbar-single {
    position: fixed;
    width: 100%;
    max-width: 600px;
    // padding: 0;
    // margin: 0 -16px;
    // height: 80px;
    bottom: calc(constant(safe-area-inset-bottom) + 80px);
    bottom: calc(env(safe-area-inset-bottom) + 80px);
    z-index: 100;
    left: 0;
    padding: 0 30px
}
.status-dialogs-toolbar-all {
    position: fixed;
    width: 100%;
    max-width: 600px;
    padding: 0;
    margin: 0 -20px;
    // height: 80px;
    bottom: calc(constant(safe-area-inset-top) + 80px);
    bottom: calc(env(safe-area-inset-top) + 80px);
    z-index: 100;
}
.status-dialogs-orders {
    // z-index: 10000;
    // position: fixed;
    // bottom: 25%;
    .swiper-slide {
        padding-left: 20px;
        &:last-child {
            padding-right: 20px;
        } 
    }
}
.status-dialogs-swiper {
    .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.status-dialogs-content {
    // width: 312px;
    min-height: 60px;
    padding: 10px 14px;
    border-radius: 16px;
    border: 2px solid #E5E6E9;
    background-color: #FFFFFF;

    &__separate {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
        margin: 10px 0 5px;
    }
    &__inline {
        display: flex;
        align-items: center;
    }
    &__paid-waiting {
        padding-left: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #9599A4;

        &__timer {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            color: #232321;
        }
    }
}
.status-dialogs-content-single {
    // width: 90%;
    // margin: 0 auto;
}
.status-dialogs-main-text {
    font-weight: 500;
    font-size: 15px;
}
.status-dialogs-additional-text {
    color: #90949A;
    font-size: 12px;
    text-transform: uppercase;
}
.status-dialogs-img {

}
.status-dialog-wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.2;
    margin-left: 10px;

}
.status-dialogs-button {
    background-color: #1f3a60;
    max-width: 104px;
    padding: 10px 21px;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    margin-left: auto;
}
</style>
